import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface LandingModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  videoUrl: string | null;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const LandingModal = ({ open, setOpen, videoUrl, setVideoUrl }: LandingModalProps) => {
  const handleOnClose = () => {
    setOpen(false);
    setVideoUrl(null);
  };

  const playerRef = useCallback(
    (node: HTMLDivElement) => {
      if (typeof window !== 'undefined' && videoUrl) {
        // @ts-ignore
        window.jwplayer(node).setup({
          file: videoUrl,
          width: '100%',
          aspectratio: '16:9',
          autostart: true,
        });
      }
    },
    [videoUrl]
  );

  useEffect(() => {
    return () => {
      // @ts-ignore
      window.jwplayer().stop();
    };
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex h-full w-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-7xl transform overflow-hidden rounded bg-landing-dark px-4 pt-14 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:pt-14">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-white hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={handleOnClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div ref={playerRef} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LandingModal;
