import type { NextPage } from 'next';
import { useEffect, useState } from 'react';

import { protectedRoutePropsWrapper, BasePageProps } from '@/utils/index';
import { PAGE } from '@/router/routes';

import { LandingLayout } from '@/components/layouts';
import { LandingModal } from '@/components/modals';
import {
  HeroSection,
  LandingVideoSection,
  ProgrammesHeading,
  LandingProgrammes,
  JoinTheRush,
} from '@/components/Landing';

const Landing: NextPage = () => {
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  return (
    <>
      <LandingLayout isWithButton>
        <HeroSection />
        <ProgrammesHeading />
        <LandingVideoSection setOpen={setOpen} setVideoUrl={setVideoUrl} />
        <LandingProgrammes setOpen={setOpen} setVideoUrl={setVideoUrl} />

        {videoUrl && (
          <LandingModal
            open={open}
            setOpen={setOpen}
            videoUrl={videoUrl}
            setVideoUrl={setVideoUrl}
          />
        )}

        <JoinTheRush />
      </LandingLayout>
    </>
  );
};

export default Landing;
